// src/components/RegistrationForm.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate

const Register = ({ openLoginModal }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://axuat.atomstate.com/api/register/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            username,
            email,
            password,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess("Registration successful!!!");
        setTimeout(() => {
          navigate("/"); // Redirect to login page
        }, 2000); // Wait 2 seconds before redirecting
      } else {
        setError(data.detail || "Registration failed");
      }
    } catch (error) {
      setError("An error occurred during registration.");
    }
  };

  return (
    <div className="">
      <div className="max-w-md w-full mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-semibold text-black mb-6">
            Register to Atomax
          </h2>
        </div>
        <div className="bg-white p-8 rounded shadow-md">
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          {success && (
            <p className="text-green-500 text-center mb-4">{success}</p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <input
                id="username"
                type="text"
                className="mt-1 p-3 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                className="mt-1 p-3 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                className="mt-1 p-3 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-950 hover:bg-green-950 text-white py-3 px-6 rounded transition duration-300"
            >
              Register
            </button>
          </form>
          <div className="mt-4 text-center flex items-center justify-center">
            <p className="text-sm text-gray-500">Already have an account?</p>
            {/* Replace the Link with a button to call openLoginModal */}
            <button
              onClick={openLoginModal}
              className="text-blue-500 ml-2 hover:text-blue-600 text-sm"
            >
              Login here
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
