// src/utils/auth.js
export const isValidToken = (token) => {
  // Example of token validation logic
  // You can implement a real API call to validate the token here
  if (!token) {
    return false;
  }

  // Additional logic to check if the token is expired or invalid
  // Assuming a token expiration time is encoded in the token
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const isExpired = payload.exp * 1000 < Date.now(); // Assuming 'exp' is the expiry time in seconds
    return !isExpired;
  } catch (error) {
    return false; // If token parsing fails, consider it invalid
  }
};
