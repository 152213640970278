import React from "react";
import "./index.css";
import HeaderPage from "./HeaderPage";
import Header from "./Header";
import HowToTranscribe from "./HowToTranscribe";
import Languages from "./Languages";
import Benefits from "./Benefits";
import FAQ from "./FAQ";
import Footer from "./Footer";

function Router() {
  return (
    <div className="Homepage">
      <Header />
      <HeaderPage />
      <HowToTranscribe />
      <Languages />
      <Benefits />
      <FAQ />
      <Footer />
    </div>
  );
}

export default Router;
