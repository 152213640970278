import React from "react";
import ASRDisplay from "./ASRDisplay";

const realTime = () => {
  return (
    // <div>
    //   <ASRDisplay />
    // </div>
    <div className="coming">
      <h1 className="animate-bounce text-6xl font-extrabold mb-8">
        Coming Soon
      </h1>
      <p className="text-2xl mb-8 font-serif">
        Our team is diligently working to bring you this exciting new feature.
        Stay tuned for updates!
      </p>
      <div className="text-6xl animate-spin">
        <i className="fas fa-hourglass-half"></i>
      </div>
    </div>
  );
};

export default realTime;
