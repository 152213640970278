import React, { useEffect, useState } from "react";
import axios from "axios";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  getTranscriptionByFileName,
  generateSummary,
  analyzeSentiment,
  createCase,
} from "../api"; // Adjust the path as needed

const Tickets = ({ token }) => {
  const [cases, setCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [summary, setSummary] = useState("");
  const [sentiment, setSentiment] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(
          "https://axuat.atomstate.com/api/plugin/sfdc/get_all_cases",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCases(response.data);
      } catch (error) {
        console.error("Error fetching cases:", error);
      }
    };

    fetchCases();
  }, [token]);

  const handleCaseClick = (caseData) => {
    setSelectedCase(caseData);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedCase(null);
  };

  const handleViewTranscription = async (audioFileName) => {
    try {
      if (!audioFileName) {
        console.error("Audio file name is undefined");
        return;
      }

      const transcription = await getTranscriptionByFileName(
        token,
        audioFileName
      );
      navigate(`/transcription/${audioFileName}`, {
        state: { transcription },
      });
    } catch (error) {
      console.error("Error fetching transcription:", error);
    }
  };

  const handleTicketFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const caseData = Object.fromEntries(formData.entries());

    // Log form data to console for debugging
    console.log("Form Data:", caseData);

    // Fetch summary and sentiment if not already loaded
    if (!summary) {
      try {
        const response = await generateSummary(
          token,
          selectedCase.audio_file_name
        );
        setSummary(response.summary);
        caseData.Customer_Voice__c = `Summary: ${
          response.summary
        }\nSentiment: ${sentiment ? sentiment.sentiment : ""}`;
      } catch (err) {
        setError(err.message);
      }
    }

    if (!sentiment) {
      try {
        const response = await analyzeSentiment(
          token,
          selectedCase.audio_file_name
        );
        setSentiment(response);
        caseData.Customer_Voice__c = `Summary: ${summary}\nSentiment: ${response.sentiment}`;
      } catch (err) {
        setError(err.message);
      }
    }

    try {
      const response = await createCase(token, caseData);
      console.log("Case created successfully:", response);
      closeModal(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error creating case:", error);

      // Log specific backend validation errors
      if (error.response && error.response.data) {
        console.error("Validation errors:", error.response.data);
        setError("Error creating case: " + JSON.stringify(error.response.data));
      } else {
        setError("Error creating case.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCase((prevState) => ({
      ...prevState,
      create_case_data: {
        ...prevState.create_case_data,
        [name]: value,
      },
    }));
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Cases</h1>
      <div className="table-container">
        <TransitionGroup component="div" className="table">
          <div className="table-header">
            <div className="table-cell">Audio File Name</div>
            <div className="table-cell">ID</div>
            <div className="table-cell">Case Number</div>
            <div className="table-cell">Timestamp</div>
            <div className="table-cell">Complaint Status</div>
            <div className="table-cell">Actions</div>
          </div>
          {cases
            .filter(
              (caseItem) =>
                caseItem.sfdc_response.Id && caseItem.sfdc_response.CaseNumber
            )
            .map((caseItem, index) => (
              <CSSTransition key={index} timeout={300} classNames="case-row">
                <div className="table-row">
                  <div className="table-cell">{caseItem.audio_file_name}</div>
                  <div className="table-cell">{caseItem.sfdc_response.Id}</div>
                  <div className="table-cell">
                    {caseItem.sfdc_response.CaseNumber}
                  </div>
                  <div className="table-cell">{caseItem.timestamp}</div>
                  <div
                    className={`table-cell ${
                      caseItem.create_case_data.Complaint_Status__c === "Closed"
                        ? "status-closed"
                        : "status-open"
                    }`}
                  >
                    {caseItem.create_case_data.Complaint_Status__c}
                  </div>
                  <div className="table-cell ">
                    <div className="flex">
                      <button
                        onClick={() => handleCaseClick(caseItem)}
                        className="view-button"
                      >
                        View Ticket
                      </button>
                      <button
                        className=" ml-2 view-button"
                        onClick={() =>
                          handleViewTranscription(caseItem.audio_file_name)
                        }
                      >
                        <span>View Transcription</span>
                      </button>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      </div>

      {selectedCase && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Case Details"
          className="modal"
          overlayClassName="overlay"
        >
          <div className="modal-content">
            <h2 className="text-xl font-bold mb-4">Case Details</h2>
            <pre className="mb-4">
              {JSON.stringify(selectedCase.create_case_data, null, 2)}
            </pre>
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center overflow-y-auto  z-50">
              <div className="bg-white p-8 rounded-lg max-w-3xl w-full h-fit ticket-form">
                <div className="flex justify-between">
                  <h2 className="text-xl mb-4">Create Ticket</h2>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="text-red-500 hover:text-red-600 focus:outline-none"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="2x"
                      className="animate-pulse"
                    />
                  </button>
                </div>
                <form onSubmit={handleTicketFormSubmit} className="space-y-4">
                  <label className="block form-group form-group">
                    Customer Name:
                    <input
                      type="text"
                      name="VW_Customer_Name__c"
                      id="VW_Customer_Name__c"
                      value={selectedCase.create_case_data.VW_Customer_Name__c}
                      onChange={handleInputChange}
                      required
                      className="block w-full mt-1 form-input animated-input"
                    />
                  </label>

                  <label className="block form-group">
                    Customer Email:
                    <input
                      type="email"
                      name="VW_Customer_Email__c"
                      id="VW_Customer_Email__c"
                      value={selectedCase.create_case_data.VW_Customer_Email__c}
                      onChange={handleInputChange}
                      required
                      className="block w-full mt-1 form-input animated-input"
                    />
                  </label>

                  <label className="block form-group">
                    Customer Mobile:
                    <input
                      type="text"
                      name="Customer_Mobile__c"
                      id="Customer_Mobile__c"
                      value={selectedCase.create_case_data.Customer_Mobile__c}
                      onChange={handleInputChange}
                      required
                      className="block w-full mt-1 form-input animated-input"
                    />
                  </label>

                  <label className="block form-group">
                    Account Name:
                    <input
                      type="text"
                      name="Account_Name__c"
                      id="Account_Name__c"
                      value={selectedCase.create_case_data.Account_Name__c}
                      onChange={handleInputChange}
                      required
                      className="block w-full mt-1 form-input animated-input"
                    />
                  </label>

                  <label className="block form-group">
                    Audio File Name:
                    <input
                      type="text"
                      name="Audio_File_Name__c"
                      id="Audio_File_Name__c"
                      value={selectedCase.audio_file_name}
                      onChange={handleInputChange}
                      required
                      className="block w-full mt-1 form-input animated-input"
                    />
                  </label>

                  <label className="block form-group">
                    Complaint Status:
                    <select
                      name="Complaint_Status__c"
                      id="Complaint_Status__c"
                      value={selectedCase.create_case_data.Complaint_Status__c}
                      onChange={handleInputChange}
                      required
                      className="block w-full mt-1 form-select animated-input"
                    >
                      <option value="Open">Open</option>
                      <option value="Closed">Closed</option>
                    </select>
                  </label>

                  <label className="block form-group">
                    Customer Voice:
                    <textarea
                      name="Customer_Voice__c"
                      id="Customer_Voice__c"
                      value={selectedCase.create_case_data.Customer_Voice__c}
                      onChange={handleInputChange}
                      rows="4"
                      required
                      className="block w-full mt-1 form-textarea animated-input"
                    />
                  </label>

                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Create Ticket
                  </button>

                  {error && (
                    <div className="mt-4 text-red-500">Error: {error}</div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Tickets;
