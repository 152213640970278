import React, { useState } from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex justify-center space-x-2 my-6">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`py-2 px-4 rounded-full bg-gray-300 hover:bg-gray-400 transition duration-300 ${
          currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        <span className="flex items-center">
          <i className="fas fa-chevron-left mr-2"></i> Previous
        </span>
      </button>
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`py-2 px-4 rounded-full ${
            number === currentPage
              ? "bg-yellow-500 text-black"
              : "bg-gray-300 hover:bg-gray-400 transition duration-300"
          }`}
        >
          <span className="flex items-center justify-center">{number}</span>
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`py-2 px-4 rounded-full bg-gray-300 hover:bg-gray-400 transition duration-300 ${
          currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        <span className="flex items-center">
          Next <i className="fas fa-chevron-right ml-2"></i>
        </span>
      </button>
    </div>
  );
};

export default Pagination;
