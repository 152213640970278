import React from "react";
import AllLanguages from "../assets/languages.png";
import { FaLanguage, FaMicrophone, FaGlobe } from "react-icons/fa";

const Languages = () => {
  return (
    <section className="languages">
      <h2 className="header-title">Discover a World of Voices</h2>
      <p className="header-description">
        Explore our extensive library of AI voices, designed to meet every need
        and preference.
      </p>
      <div className="languages_ct">
        <div className="text-content">
          <h3 className="text-title">Over 100 Languages</h3>
          <p className="text-description">
            <div className="b-icons">
              <FaGlobe className="icon" />
            </div>
            Our AI voice technology covers a wide range of languages and
            accents, offering incredible versatility and customization.
          </p>
          <p className="text-description">
            <div className="b-icons">
              <FaLanguage className="icon" />
            </div>
            Personalize your experience with precise language and inflection
            adjustments.
          </p>
          <p className="text-description">
            <div className="b-icons">
              <FaMicrophone className="icon" />
            </div>
            Experience clear, natural, and expressive voice generation.
          </p>
        </div>
        <div className="image-container">
          <img src={AllLanguages} alt="AI Voice Languages" />
        </div>
      </div>
    </section>
  );
};

export default Languages;
