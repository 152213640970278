import React, { useState, useEffect, useRef } from "react";
import { FiSearch, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [user, setUser] = useState(null); // State to hold user details
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          "https://axuat.atomstate.com/api/user/me/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you store token in localStorage
            },
          }
        );
        if (response.ok) {
          const userData = await response.json();
          if (userData) {
            setUser(userData);
          } else {
            // Redirect to '/' if userData is not available
            navigate("/");
          }
        } else {
          console.error("Failed to fetch user details");
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/");
      }
    };

    fetchUser();
  }, [navigate]);

  const handleUserIconClick = () => {
    setShowUserDetails(!showUserDetails);
  };

  const handleSearchIconClick = () => {
    setShowSearchBar(!showSearchBar);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowUserDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <header className="flex items-center justify-between px-4 py-2 headbg text-white">
      <div className="text-lg font-bold">Transcription App</div>

      <div className="text-xs headcolor">
        Deign and developed by Suryakant Kumar [full stack developer
        -8051942924]
      </div>
      <div className="flex items-center">
        <div ref={dropdownRef} className="relative">
          <FiUser
            className="cursor-pointer"
            size={24}
            onClick={handleUserIconClick}
          />
          {showUserDetails && user && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 text-black">
              <div className="px-4 py-2 border-b">
                <strong>{user.username}</strong>
                <p className="text-sm text-gray-600">{user.email}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
