import React, { useState } from "react";
import logo from "../assets/atomax_small_1.png";
import { useLocation } from "react-router-dom";
import Modal from "./Modal";
import Login from "../components/Login";
import Register from "../components/RegistrationForm";

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const handleSetToken = (token) => {
    localStorage.setItem("token", token);
    window.location.href = "/home"; // Redirect to /home
  };

  const handleOpenLoginModal = () => {
    setShowRegisterModal(false); // Close the register modal if open
    setShowLoginModal(true); // Open the login modal
  };

  const handleOpenRegisterModal = () => {
    setShowLoginModal(false); // Close the login modal if open
    setShowRegisterModal(true); // Open the register modal
  };

  return (
    <header className="site-header">
      <div className="headerContainer">
        <div className="logo">
          <img src={logo} alt=".ax" className="h-10 w-auto" />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <a
                href="/"
                className={activeLink === "/" ? "active" : ""}
                onClick={() => handleLinkClick("/")}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/aboutUs"
                className={activeLink === "/aboutUs" ? "active" : ""}
                onClick={() => handleLinkClick("/aboutUs")}
              >
                About Us
              </a>
            </li>
            {/* <li>
              <a
                href="/pricing"
                className={activeLink === "/pricing" ? "active" : ""}
                onClick={() => handleLinkClick("/pricing")}
              >
                Pricing
              </a>
            </li> */}
            <li>
              <a
                href="/faq"
                className={activeLink === "/faq" ? "active" : ""}
                onClick={() => handleLinkClick("/faq")}
              >
                FAQ
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className={activeLink === "/contact" ? "active" : ""}
                onClick={() => handleLinkClick("/contact")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
        <div className="header-buttons">
          <button
            className="login-button login_button"
            onClick={handleOpenLoginModal}
          >
            Login
          </button>
          <button className="start-button" onClick={handleOpenRegisterModal}>
            Get Started Free
          </button>
        </div>
      </div>

      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <Login
          setToken={handleSetToken}
          openRegisterModal={handleOpenRegisterModal}
        />
      </Modal>

      <Modal
        isOpen={showRegisterModal}
        onClose={() => setShowRegisterModal(false)}
      >
        <Register openLoginModal={handleOpenLoginModal} />
      </Modal>
    </header>
  );
};

export default Header;
