import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaTachometerAlt,
  FaFileAudio,
  FaBroadcastTower,
  FaClipboardList,
} from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import logo from "../assets/atomax_small_1.png";
const Sidebar = ({ onLogout }) => {
  const linkClass = "hover:text-yellow-500 transition duration-300";

  return (
    <div className="w-64 sidebar text-white h-screen flex flex-col justify-between shadow-lg">
      <div className="p-6">
        <div className="flex flex-col items-center justify-center h-32 mb-8 ">
          {/* <h1 className="text-7xl font-extrabold">.ax</h1> */}
          <a href="/">
            <img src={logo} alt=".ax" className="h-14 w-auto " />
          </a>
        </div>
        <ul className="space-y-6 text-[22px]">
          <li className="flex items-center ">
            <FaTachometerAlt className="mr-4" />
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive ? `text-yellow-500 ${linkClass}` : linkClass
              }
            >
              Dashboards
            </NavLink>
          </li>
          <li className="flex items-center">
            <FaFileAudio className="mr-4" />
            <NavLink
              to="/audio-files"
              className={({ isActive }) =>
                isActive ? `text-yellow-500 ${linkClass}` : linkClass
              }
            >
              Audio Files
            </NavLink>
          </li>
          <li className="flex items-center">
            <FaClipboardList className="mr-4" />
            <NavLink
              to="/tickets"
              className={({ isActive }) =>
                isActive ? `text-yellow-500 ${linkClass}` : linkClass
              }
            >
              Tickets
            </NavLink>
          </li>
          <li className="flex items-center">
            <FaBroadcastTower className="mr-4" />
            <NavLink
              to="/realTime"
              className={({ isActive }) =>
                isActive ? `text-yellow-500 ${linkClass}` : linkClass
              }
            >
              Real-Time
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="p-6 flex flex-col items-center">
        <button
          className="text-white py-2 px-4 rounded-lg flex items-center justify-center w-full bg-red-600 hover:bg-red-700 transition duration-300 shadow-md"
          onClick={onLogout}
        >
          <MdLogout className="mr-2" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
