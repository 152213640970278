import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import {
  getTranscriptionByFileName,
  analyzeSentiment,
  generateSummary,
  extractNamedEntities,
  extractActionPoints,
} from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaSpinner } from "react-icons/fa";
import { Chart } from "react-google-charts";
import {
  AiOutlineSmile,
  AiOutlineFrown,
  AiOutlineMeh,
  AiOutlineAlert,
} from "react-icons/ai";
import AudioPlayer from "./AudioPlayer";

const SentimentIcon = ({ type }) => {
  switch (type) {
    case "Positive":
      return <AiOutlineSmile />;
    case "Negative":
      return <AiOutlineFrown />;
    case "Neutral":
      return <AiOutlineMeh />;
    case "Mixed":
      return <AiOutlineAlert />;
    default:
      return null;
  }
};
const getEntityColor = (type) => {
  switch (type.toLowerCase()) {
    case "person":
      return "#4caf50";
    case "location":
      return "#2196f3";
    case "organization":
      return "#ff9800";
    case "date":
      return "#9c27b0";
    default:
      return "#607d8b";
  }
};

const API_URL = "https://axuat.atomstate.com/api";

const TranscriptionDetails = ({ token }) => {
  const location = useLocation();
  const fileName = location.pathname.split("/").pop(); // Extract fileName from the URL
  const [transcription, setTranscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("transcription");
  const [summary, setSummary] = useState(null);
  const [sentiment, setSentiment] = useState(null);
  const [namedEntities, setNamedEntities] = useState(null);
  const [actionPoints, setActionPoints] = useState(null);
  const [translatedSummary, setTranslatedSummary] = useState(null);
  const [translatedSentiment, setTranslatedSentiment] = useState(null);
  const [translatedNamedEntities, setTranslatedNamedEntities] = useState(null);
  const [translatedActionPoints, setTranslatedActionPoints] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default language is English
  const [showTicketForm, setShowTicketForm] = useState(false);
  const [translatedTranscription, setTranslatedTranscription] = useState(null); // Add this line
  const [submitting, setSubmitting] = useState(false); // Add this line
  const [submissionStatus, setSubmissionStatus] = useState(""); // Add this line

  const [ticketFormData, setTicketFormData] = useState({
    VW_Customer_Name__c: "",
    VW_Customer_Email__c: "",
    VW_Customer_Mobile__c: "",
    VW_Model__c: "",
    VW_VIN__c: "",
    VW_Type_of_Complaint__c: "Service Concern", // Default value
    Complaint_Category__c: "",
    Complaint_Sub_I__c: "",
    Date_of_Email_Call__c: "",
    Customer_Voice__c: "",
    VW_Sub_Category__c: "",
    Complaint_Register_By__c: "Customer Experience", // Default value
    Complaint_Status__c: "Open: New Complaint", // Default value
    Status: "New", // Default value
    VW_Repeated_Complaint__c: "No", // Default value
    Active__c: "true", // Default value
    Source_Channel__c: "Customer Experience", // Default value
    RecordType_Developer_Name__c: "Skoda Complaint", // Default value
    VW_Brand__c: "Skoda", // Default value
    VW_Dealer_Code__c: "",
    Selling_Dealer_Code__c: "",
    Origin: "SMS", // Default value
    VW_Date_of_Sale__c: "",
    External_Id__c: 392, // Default value
    audio_file_name: fileName,
  });

  useEffect(() => {
    const fetchTranscription = async () => {
      try {
        const response = await getTranscriptionByFileName(token, fileName);
        setTranscription(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTranscription();
  }, [token, fileName]);

  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    try {
      switch (tab) {
        case "summary":
          if (!summary) {
            const response = await generateSummary(token, fileName);
            setSummary(response.summary);
            if (selectedLanguage !== "en") {
              const translated = await translateText(
                response.summary,
                selectedLanguage
              );
              setTranslatedSummary(translated.translated_text);
            }
          }
          break;
        case "sentiment":
          if (!sentiment) {
            const response = await analyzeSentiment(token, fileName);
            setSentiment(response);
            if (selectedLanguage !== "en") {
              const translated = await translateText(
                `Sentiment: ${response.sentiment}`,
                selectedLanguage
              );
              setTranslatedSentiment(translated.translated_text);
            }
          }
          break;
        case "ner":
          if (!namedEntities) {
            const response = await extractNamedEntities(token, fileName);
            setNamedEntities(response.entities);
            if (selectedLanguage !== "en") {
              const entitiesText = response.entities
                .map((entity) => `${entity.Text} - Type: ${entity.Type}`)
                .join(", ");
              const translated = await translateText(
                entitiesText,
                selectedLanguage
              );
              setTranslatedNamedEntities(translated.translated_text);
            }
          }
          break;
        case "actions":
          if (!actionPoints) {
            const response = await extractActionPoints(token, fileName);
            setActionPoints(response.action_points);
            if (selectedLanguage !== "en") {
              const translated = await translateText(
                response.action_points,
                selectedLanguage
              );
              setTranslatedActionPoints(translated.translated_text);
            }
          }
          break;
        default:
          break;
      }
    } catch (err) {
      setError(err.message);
    }
  };
  const handleTicketFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Add this line
    setSubmissionStatus(""); // Add this line

    // Fetch summary and sentiment if not already loaded
    if (!summary) {
      try {
        const response = await generateSummary(token, fileName);
        setSummary(response.summary);
      } catch (err) {
        setError(err.message);
      }
    }

    if (!sentiment) {
      try {
        const response = await analyzeSentiment(token, fileName);
        setSentiment(response);
      } catch (err) {
        setError(err.message);
      }
    }

    const updatedTicketFormData = {
      ...ticketFormData,
      Customer_Voice__c: `Summary: ${summary}\nSentiment: ${sentiment.sentiment}`,
    };

    try {
      const response = await fetch(`${API_URL}/plugin/sfdc/create_case`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedTicketFormData),
      });
      if (response.ok) {
        // Handle success, maybe close the form and show a success message with animation
        setShowTicketForm(false);
        setSubmissionStatus("success"); // Add this line
        showSuccessAlert();
        // Optionally, you can clear the form fields here
        setTimeout(() => {
          window.location.reload(); // Add this line
        }, 1000); // Reload after 3 seconds
      } else {
        throw new Error("Failed to create ticket");
      }
    } catch (err) {
      setError(err.message);
      setSubmissionStatus("error"); // Add this line
    } finally {
      setSubmitting(false); // Add this line
    }
  };

  const showSuccessAlert = () => {
    const successAlert = document.createElement("div");
    successAlert.classList.add("success-alert");
    successAlert.innerText = "Ticket created successfully!";
    document.body.appendChild(successAlert);

    setTimeout(() => {
      successAlert.remove();
    }, 3000);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setTicketFormData((prevData) => ({
      ...prevData,
      ...ticketFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);

    try {
      if (summary) {
        const response = await translateText(summary, newLanguage);
        setTranslatedSummary(response.translated_text);
      }

      if (sentiment) {
        const response = await translateText(
          `Sentiment: ${sentiment.sentiment}`,
          newLanguage
        );
        setTranslatedSentiment(response.translated_text);
      }

      if (transcription && transcription.sentences) {
        const sentencesText = transcription.sentences
          .map((sentence) => `${sentence.speaker}: ${sentence.sentence}`)
          .join("\n");
        const response = await translateText(sentencesText, newLanguage);
        setTranslatedTranscription(response.translated_text);
      }

      if (namedEntities) {
        const entitiesText = namedEntities
          .map((entity) => `${entity.Text} - Type: ${entity.Type}`)
          .join(", ");
        const response = await translateText(entitiesText, newLanguage);
        setTranslatedNamedEntities(response.translated_text);
      }

      if (actionPoints) {
        const response = await translateText(actionPoints, newLanguage);
        setTranslatedActionPoints(response.translated_text);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const translateText = async (text, targetLanguage) => {
    const response = await fetch(`${API_URL}/translate/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: text,
        target_language: targetLanguage,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to translate text");
    }
    return await response.json();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (!transcription || !transcription.sentences) {
    return <p>No transcription available for this file.</p>;
  }

  const transcriptionContent = translatedTranscription
    ? translatedTranscription.split("\n").map((sentence, index) => (
        <p key={index}>
          {sentence.split(": ")[0]}: {sentence.split(": ")[1]}
        </p>
      ))
    : transcription.sentences.map((sentence, index) => (
        <p key={index}>
          {sentence.speaker}: {sentence.sentence}
        </p>
      ));

  const languageOptions = [
    { code: "en", name: "English" },
    { code: "es", name: "Spanish" },
    { code: "fr", name: "French" },
    { code: "de", name: "German" },
    { code: "hi", name: "Hindi" },
    { code: "bn", name: "Bengali" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "mr", name: "Marathi" },
    { code: "gu", name: "Gujarati" },
    { code: "kn", name: "Kannada" },
    { code: "ml", name: "Malayalam" },
    { code: "or", name: "Odia" },
    { code: "pa", name: "Punjabi" },
    { code: "as", name: "Assamese" },
    { code: "sd", name: "Sindhi" },
    { code: "ur", name: "Urdu" },
    { code: "sanskrit", name: "Sanskrit" },
    { code: "ne", name: "Nepali" },
    { code: "bh", name: "Bhojpuri" },
    { code: "pt", name: "Portuguese" },
    { code: "it", name: "Italian" },
    { code: "nl", name: "Dutch" },
    { code: "ru", name: "Russian" },
    { code: "zh", name: "Chinese (Simplified)" },
    { code: "ja", name: "Japanese" },
    { code: "ko", name: "Korean" },
    { code: "ar", name: "Arabic" },
    { code: "tr", name: "Turkish" },
    { code: "vi", name: "Vietnamese" },
    { code: "th", name: "Thai" },
    { code: "pl", name: "Polish" },
    { code: "sv", name: "Swedish" },
    { code: "no", name: "Norwegian" },
    { code: "da", name: "Danish" },
    { code: "fi", name: "Finnish" },
    { code: "hu", name: "Hungarian" },
    { code: "cs", name: "Czech" },
    { code: "sk", name: "Slovak" },
    { code: "ro", name: "Romanian" },
    { code: "he", name: "Hebrew" },
    { code: "bg", name: "Bulgarian" },
    { code: "hr", name: "Croatian" },
    { code: "sr", name: "Serbian" },
    { code: "sl", name: "Slovenian" },
    { code: "et", name: "Estonian" },
    { code: "lt", name: "Lithuanian" },
    { code: "lv", name: "Latvian" },
    // Add more languages here as needed
  ];

  return (
    <div className="transcription-details">
      <div className=" flex justify-between items-center px-6 mb-2">
        <div>
          <AudioPlayer src={transcription.audio_file_uri} type="audio/wav" />
        </div>
        <div>
          <button
            onClick={() => setShowTicketForm(true)}
            className="bg-green-900 hover:bg-green-800 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaPlus className="mr-2" />
            Create Ticket
          </button>
        </div>
      </div>
      <div className="info-panel">
        <div className="info-item">
          <strong>Number of Speakers</strong>
          <p>{Object.keys(transcription.speaker_confidences).length}</p>
        </div>
        <div className="info-item">
          <strong>Interaction Start Time</strong>
          <p>{transcription.timestamp}</p>
        </div>
        <div className="info-item">
          <strong>Conversation ID</strong>
          <p>{fileName}</p>
        </div>
        <div className="info-item">
          <strong>Speaker Confidences</strong>
          <ul>
            {Object.entries(transcription.speaker_confidences).map(
              ([speaker, confidence], index) => (
                <li key={index}>
                  {speaker}: {confidence.toFixed(2)}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="info-item">
          <strong>Ticket Details</strong>
          {transcription.sfdc_response &&
          typeof transcription.sfdc_response === "object" &&
          Object.keys(transcription.sfdc_response).length > 0 ? (
            <ul>
              {Object.entries(transcription.sfdc_response)
                .filter(([key]) => key === "Id" || key === "CaseNumber") // Filter to include only 'Id' and 'CaseNumber'
                .map(([key, value], index) => (
                  <li key={index}>
                    {key}: {value}
                  </li>
                ))}
            </ul>
          ) : (
            <p>Ticket not available</p>
          )}
        </div>
      </div>
      <div className="tabs">
        <button
          className={activeTab === "transcription" ? "active" : ""}
          onClick={() => handleTabChange("transcription")}
        >
          Transcription
        </button>
        <button
          className={activeTab === "summary" ? "active" : ""}
          onClick={() => handleTabChange("summary")}
        >
          Summary
        </button>
        <button
          className={activeTab === "sentiment" ? "active" : ""}
          onClick={() => handleTabChange("sentiment")}
        >
          Sentiment Analysis
        </button>
        <button
          className={activeTab === "ner" ? "active" : ""}
          onClick={() => handleTabChange("ner")}
        >
          NER
        </button>
        <button
          className={activeTab === "actions" ? "active" : ""}
          onClick={() => handleTabChange("actions")}
        >
          Actions
        </button>
      </div>
      {activeTab === "transcription" && (
        <div className="transcript">
          <div className="flex justify-between items-center mb-2">
            <div>
              <h3 className="transcriptionh">Transcript</h3>
            </div>
            <div className="language-selector">
              <select
                id="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languageOptions.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="transcript-content">
            <div>{transcriptionContent}</div>
          </div>
        </div>
      )}

      {activeTab === "summary" && (
        <div className="transcript">
          <div className="flex justify-between items-center mb-2">
            <div>
              <h3 className="transcriptionh">Summary</h3>
            </div>
            <div className="language-selector">
              <select
                id="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languageOptions.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="transcript-content summary ">
            <p>{translatedSummary || summary || "Loading summary..."}</p>
          </div>
        </div>
      )}
      {activeTab === "sentiment" && (
        <div className="sentiment transcript">
          <div className="flex justify-between mb-4 content-center">
            <h3>Sentiment Analysis</h3>
            <div className="language-selector">
              <select
                id="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languageOptions.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {sentiment ? (
            <>
              <div className="sentiment-info">
                {/* <p>Sentiment: {sentiment.sentiment}</p> */}
                <p>
                  {translatedSentiment || `Sentiment: ${sentiment.sentiment}`}
                </p>
                <div className="sentiment-icons">
                  {Object.entries(sentiment.sentiment_score).map(
                    ([type, score], index) => (
                      <div key={index} className="sentiment-icon">
                        <SentimentIcon type={type} />
                        <p>{type}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="sentiment-chart">
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="PieChart"
                  loader={<div>Loading Chart...</div>}
                  data={[
                    ["Sentiment", "Score"],
                    ...Object.entries(sentiment.sentiment_score).map(
                      ([type, score]) => [type, score]
                    ),
                  ]}
                  options={{
                    title: "Sentiment Scores",
                    pieHole: 0.4,
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </>
          ) : (
            <p>Loading sentiment analysis...</p>
          )}
        </div>
      )}
      {activeTab === "ner" && namedEntities && (
        <div className="ner">
          <div className="flex justify-between mb-4 content-center">
            <h3>Named Entity Recognition (NER)</h3>
            <div className="language-selector">
              <select
                id="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languageOptions.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {namedEntities ? (
            <ul className="ner-list transcript-content">
              {typeof translatedNamedEntities === "string"
                ? translatedNamedEntities.split(", ").map((entity, index) => (
                    <li
                      key={index}
                      className="ner-item "
                      style={{ borderLeftColor: getEntityColor(entity) }}
                    >
                      <span className="ner-text">{entity}</span>
                    </li>
                  ))
                : namedEntities.map((entity, index) => (
                    <li
                      key={index}
                      className="ner-item"
                      style={{ borderLeftColor: getEntityColor(entity.Type) }}
                    >
                      <span className="ner-text">{entity.Text}</span>
                      <span className="ner-type">({entity.Type})</span>
                    </li>
                  ))}
            </ul>
          ) : (
            <p>Loading named entities...</p>
          )}
        </div>
      )}

      {activeTab === "actions" && (
        <div className="actions-section ">
          <div className="flex justify-between mb-4 content-center">
            <h3>Action Points</h3>
            <div className="language-selector">
              <select
                id="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languageOptions.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="action-points-content transcript-content"
            dangerouslySetInnerHTML={{
              __html:
                translatedActionPoints ||
                actionPoints ||
                "Loading Action Points...",
            }}
          />
        </div>
      )}

      {/* Ticket creation form */}
      {showTicketForm && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center overflow-y-auto  z-50">
          <div className="bg-white p-8 rounded-lg max-w-3xl w-full h-fit ticket-form ">
            <div className="flex justify-between">
              <h2 className="text-xl mb-4">Create Ticket</h2>
              <button
                type="button"
                onClick={() => setShowTicketForm(false)}
                className="text-red-500 hover:text-red-600 focus:outline-none"
                style={{ position: "relative", width: "40px", height: "40px" }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="2x"
                  className="animate-pulse"
                />
              </button>
            </div>
            <form onSubmit={handleTicketFormSubmit} className="space-y-4">
              <label className="block form-group form-group">
                Customer Name:
                <input
                  type="text"
                  name="VW_Customer_Name__c"
                  id="VW_Customer_Name__c"
                  value={ticketFormData.VW_Customer_Name__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>

              <label className="block form-group">
                Customer Email:
                <input
                  type="email"
                  name="VW_Customer_Email__c"
                  id="VW_Customer_Email__c"
                  value={ticketFormData.VW_Customer_Email__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>

              <label className="block form-group">
                Customer Mobile:
                <input
                  type="tel"
                  name="VW_Customer_Mobile__c"
                  id="VW_Customer_Mobile__c"
                  value={ticketFormData.VW_Customer_Mobile__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                VW Model:
                <input
                  type="text"
                  name="VW_Model__c"
                  id="VW_Model__c"
                  value={ticketFormData.VW_Model__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                VW VIN:
                <input
                  type="text"
                  name="VW_VIN__c"
                  id="VW_VIN__c"
                  value={ticketFormData.VW_VIN__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Type of Complaint:
                <select
                  name="VW_Type_of_Complaint__c"
                  id="VW_Type_of_Complaint__c"
                  value={ticketFormData.VW_Type_of_Complaint__c}
                  onChange={handleInputChange}
                  required
                  className="block form-group w-full mt-1 form-input animated-input"
                >
                  <option value="Service Concern">Service Concern</option>
                  <option value="Sales Concern">Sales Concern</option>
                  <option value="Service Query">Service Query</option>
                  <option value="Sales Query">Sales Query</option>
                </select>
              </label>
              <label className="block form-group">
                Complaint Category:
                <input
                  type="text"
                  name="Complaint_Category__c"
                  id="Complaint_Category__c"
                  value={ticketFormData.Complaint_Category__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Complaint Sub Category:
                <input
                  type="text"
                  name="Complaint_Sub_I__c"
                  id="Complaint_Sub_I__c"
                  value={ticketFormData.Complaint_Sub_I__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Date of Email/Call:
                <input
                  type="date"
                  name="Date_of_Email_Call__c"
                  id="Date_of_Email_Call__c"
                  value={ticketFormData.Date_of_Email_Call__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Customer Voice:
                <textarea
                  name="Customer_Voice__c"
                  id="Customer_Voice__c"
                  value={`Summary: ${summary}\nSentiment: ${
                    sentiment ? sentiment.sentiment : ""
                  }`}
                  onChange={handleInputChange}
                  required
                  disabled
                  className="block form-group form-input animated-input w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                ></textarea>
              </label>
              <label className="block form-group">
                VW Sub Category:
                <input
                  type="text"
                  name="VW_Sub_Category__c"
                  id="VW_Sub_Category__c"
                  value={ticketFormData.VW_Sub_Category__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Complaint Register By:
                <select
                  name="Complaint_Register_By__c"
                  id="Complaint_Register_By__c"
                  value={ticketFormData.Complaint_Register_By__c}
                  onChange={handleInputChange}
                  required
                  className="block form-group w-full mt-1 form-input animated-input"
                >
                  <option value="Customer Experience">
                    Customer Experience
                  </option>
                  <option value="Call Centre">Call Centre</option>
                </select>
              </label>
              <label className="block form-group">
                Complaint Status:
                <select
                  name="Complaint_Status__c"
                  id="Complaint_Status__c"
                  value={ticketFormData.Complaint_Status__c}
                  onChange={handleInputChange}
                  required
                  className="block form-group w-full mt-1 form-input animated-input"
                >
                  <option value="Open: New Complaint">
                    Open: New Complaint
                  </option>
                  <option value="New Complaint">New Complaint</option>
                  <option value="Closed">Closed</option>
                  <option value="Revert to dealer">Revert to dealer</option>
                </select>
              </label>
              <label className="block form-group">
                Status:
                <input
                  type="text"
                  name="Status"
                  id="Status"
                  value={ticketFormData.Status}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Repeated Complaint:
                <select
                  name="VW_Repeated_Complaint__c"
                  id="VW_Repeated_Complaint__c"
                  value={ticketFormData.VW_Repeated_Complaint__c}
                  onChange={handleInputChange}
                  required
                  className="block form-group w-full mt-1 form-input animated-input"
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </label>
              <label className="block form-group">
                Active:
                <input
                  type="checkbox"
                  name="Active__c"
                  id="Active__c"
                  checked={ticketFormData.Active__c}
                  onChange={handleInputChange}
                  className="ml-2 h-6 w-6 form-checkbox"
                />
                <span className="checkbox-custom"></span>
              </label>

              <label className="block form-group">
                Source Channel:
                <select
                  name="Source_Channel__c"
                  id="Source_Channel__c"
                  value={ticketFormData.Source_Channel__c}
                  onChange={handleInputChange}
                  required
                  className="block form-group w-full mt-1 form-input animated-input"
                >
                  <option value="Customer Experience">
                    Customer Experience
                  </option>
                  <option value="Call Centre">Call Centre</option>
                </select>
              </label>
              <label className="block form-group">
                Record Type Developer Name:
                <input
                  type="text"
                  name="RecordType_Developer_Name__c"
                  id="RecordType_Developer_Name__c"
                  value={ticketFormData.RecordType_Developer_Name__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                VW Brand:
                <input
                  type="text"
                  name="VW_Brand__c"
                  id="VW_Brand__c"
                  value={ticketFormData.VW_Brand__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                VW Dealer Code:
                <input
                  type="text"
                  name="VW_Dealer_Code__c"
                  id="VW_Dealer_Code__c"
                  value={ticketFormData.VW_Dealer_Code__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Selling Dealer Code:
                <input
                  type="text"
                  name="Selling_Dealer_Code__c"
                  id="Selling_Dealer_Code__c"
                  value={ticketFormData.Selling_Dealer_Code__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                Origin:
                <input
                  type="text"
                  name="Origin"
                  id="Origin"
                  value={ticketFormData.Origin}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                VW Date of Sale:
                <input
                  type="date"
                  name="VW_Date_of_Sale__c"
                  id="VW_Date_of_Sale__c"
                  value={ticketFormData.VW_Date_of_Sale__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <label className="block form-group">
                External ID:
                <input
                  type="number"
                  name="External_Id__c"
                  id="External_Id__c"
                  value={ticketFormData.External_Id__c}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>

              <label className="block form-group">
                File ID:
                <input
                  type="text"
                  name="audio_file_name"
                  id="audio_file_name"
                  value={ticketFormData.audio_file_name}
                  onChange={handleInputChange}
                  required
                  className="block w-full mt-1 form-input animated-input"
                />
              </label>
              <div>
                <button
                  type="submit"
                  disabled={submitting}
                  className={`submit ${submitting ? "submitting" : ""}`}
                >
                  {submitting ? (
                    <div className="flex gap-1 items-center">
                      <div>
                        <FaSpinner className="spinner-icon" />
                      </div>
                      <div> Please Wait submitting....</div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
                {submissionStatus === "success" && (
                  <div className="success-message">
                    Ticket created successfully!
                  </div>
                )}
                {submissionStatus === "error" && (
                  <div className="error-message">
                    Failed to create ticket. Please try again.
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TranscriptionDetails;

//new code for the tranlation
