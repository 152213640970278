import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa"; // You'll need to install react-icons package

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <section className="faq">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((item, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-header" onClick={() => handleToggle(index)}>
            <h3>{item.question}</h3>
            {expanded === index ? <FaMinus /> : <FaPlus />}
          </div>
          {expanded === index && <p>{item.answer}</p>}
        </div>
      ))}
    </section>
  );
};

const faqData = [
  {
    question: "How can I generate transcriptions from audio files?",
    answer:
      "You can upload an audio file to our system, and it will process the file to generate a text transcription. This feature is ideal for converting meetings, lectures, and other spoken content into written form.",
  },
  {
    question: "Can I view the transcriptions in multiple languages?",
    answer:
      "Yes, after generating the transcription, you can view it in various languages as required. Our system supports multiple language translations for your convenience.",
  },
  {
    question: "How does the text analysis work?",
    answer:
      "Once you have your text transcription, our system can analyze it to provide a summary, identify named entities, assess sentiment, and generate action points. This helps in extracting key insights and understanding the content better.",
  },
  {
    question: "Can I download the transcriptions and analysis?",
    answer:
      "Yes, you can download the transcriptions and analysis as a PDF. This allows you to keep a record of your data and share it as needed.",
  },
  {
    question: "Can I view and manage my historical records?",
    answer:
      "Yes, you can view all your previous records and manage them as needed. This includes the ability to delete old records or generate new analyses.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Absolutely. We prioritize your data's security with high-level encryption and secure storage practices to ensure that your information remains safe and private.",
  },
  {
    question: "Does the system support real-time updates?",
    answer:
      "Yes, our system supports real-time functionality, allowing you to receive updates and perform analysis as data is processed. This is particularly useful for live events or ongoing conversations.",
  },
];

export default FAQ;
