import React, { useState, useEffect } from "react";
import { uploadAudio } from "../api";
import {
  FaCloudUploadAlt,
  FaSpinner,
  FaFileAudio,
  FaLink,
  FaTrash,
  FaPaperPlane,
  FaCheckCircle,
  FaTimesCircle,
  FaMicrophone,
  FaStopCircle,
  FaRegEdit,
} from "react-icons/fa";
import classNames from "classnames";
const UploadAudio = ({ token }) => {
  const [files, setFiles] = useState([]);
  const [response, setResponse] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [editingFileName, setEditingFileName] = useState(null);
  const [newFileName, setNewFileName] = useState("");

  useEffect(() => {
    if (recording) {
      setTimer(setInterval(() => setRecordingTime((prev) => prev + 1), 1000));
    } else {
      clearInterval(timer);
      setRecordingTime(0);
    }
    return () => clearInterval(timer);
  }, [recording]);

  useEffect(() => {
    if (showSuccess || showFailure) {
      const timeoutId = setTimeout(() => {
        setShowSuccess(false);
        setShowFailure(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [showSuccess, showFailure]);

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setFiles([...files, ...filesArray]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const filesArray = Array.from(e.dataTransfer.files);
    setFiles([...files, ...filesArray]);
    setDragging(false);
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        const chunks = [];

        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        recorder.onstop = async () => {
          stream.getTracks().forEach((track) => track.stop());
          const blob = new Blob(chunks, { type: "audio/wav" });
          const filename = `recorded-${Date.now()}.wav`;
          const file = new File([blob], filename, { type: "audio/wav" });
          setFiles([...files, file]);
          setRecording(false);
        };

        setMediaRecorder(recorder);
        recorder.start();
        setRecording(true);
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }
  };

  const handleUrlPaste = async (e) => {
    const url = e.clipboardData.getData("Text");
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Invalid URL or unsupported audio format.");
      }
      const blob = await response.blob();
      const filename = `url-audio-${Date.now()}.wav`;
      const file = new File([blob], filename, { type: "audio/wav" });
      setFiles([...files, file]);
      setShowSuccess(true);
      setShowFailure(false);
    } catch (error) {
      console.error("Error fetching audio from URL:", error);
      setShowFailure(true);
      setShowSuccess(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      alert("Please drop files or select at least one file to upload.");
      return;
    }

    setUploading(true);
    try {
      const response = await uploadAudio(token, files);
      console.log("Upload response:", response);

      setResponse(response);

      if (
        response.uploaded_files &&
        response.uploaded_files.length > 0 &&
        response.transcription_status === "Processing"
      ) {
        setShowSuccess(true);
        setShowFailure(false);
      } else {
        setShowSuccess(false);
        setShowFailure(true);
      }

      // Clear files on success or failure
      setFiles([]);
    } catch (error) {
      console.error("Upload failed:", error);
      setShowSuccess(false);
      setShowFailure(true);
      setFiles([]); // Clear files on error
    } finally {
      setUploading(false);
    }
  };

  const removeFile = (fileToRemove) => {
    const filteredFiles = files.filter((file) => file !== fileToRemove);
    setFiles(filteredFiles);
  };

  const handleEditFileName = (file) => {
    setEditingFileName(file);
    setNewFileName(file.name.replace(/\.[^/.]+$/, "")); // Remove extension
  };

  const handleFileNameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const handleFileNameSubmit = (file) => {
    const updatedFiles = files.map((f) => {
      if (f === file) {
        const newName = `${newFileName}.wav`; // Append the extension
        const updatedFile = new File([f], newName, { type: f.type });
        return updatedFile;
      }
      return f;
    });
    setFiles(updatedFiles);
    setEditingFileName(null);
  };
  return (
    <div>
      <div className="mb-4 flex gap-4">
        <div
          className={classNames(
            "upload-dropzone border-4 border-dashed border-gray-300 rounded-lg p-4 text-center transition duration-300 ease-in-out transform relative hover:bg-green-950 hover:border-green-950",
            { "border-blue-500 scale-105": dragging }
          )}
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            setDragging(true);
          }}
          onDragLeave={() => setDragging(false)}
        >
          <input
            id="fileInput"
            type="file"
            className="absolute inset-0 opacity-0 cursor-pointer"
            multiple
            onChange={handleFileChange}
            disabled={dragging || recording}
          />

          {/* upload */}
          <div className="upload-content">
            <div className="mb-4">
              <FaCloudUploadAlt
                className={classNames(
                  "text-4xl text-white  mx-auto",
                  dragging ? "text-blue-500 animate-bounce" : "text-gray-500"
                )}
              />
            </div>
            <p
              className={`upload-text text-lg font-semibold text-white ${
                dragging ? "text-blue-500" : "text-gray-500"
              } transition-colors duration-300`}
            >
              [ Drag & Drop ] or [Select File]
            </p>
          </div>
        </div>

        <div className=" flex justify-between gap-4">
          <div
            className={classNames(
              "border-4 border-dashed border-gray-300 rounded-lg p-4 text-center transition duration-300 ease-in-out transform relative cursor-pointer hover:bg-green-950 hover:border-green-950",
              { "border-red-500 scale-105": recording }
            )}
            onClick={recording ? stopRecording : startRecording}
          >
            <div className="flex items-center justify-center flex-col">
              {recording ? (
                <>
                  <FaStopCircle className="text-6xl text-red-500 mb-2 animate-pulse" />
                  <span className="text-red-500 font-bold text-lg">
                    Recording... {recordingTime}s
                  </span>
                </>
              ) : (
                <>
                  <FaMicrophone className="text-4xl text-white mb-2 mt-4" />
                  <span className="text-white font-semibold text-lg">
                    Click to Record
                  </span>
                </>
              )}
            </div>
          </div>

          {/* Enhanced Paste URL Section */}
          <div className="border-4 border-dashed border-gray-300 rounded-lg p-4 text-center transition duration-300 ease-in-out transform relative hover:shadow-lg hover:bg-green-950 hover:border-green-950">
            <div className="flex items-center justify-center">
              <div className="tooltip relative w-96">
                <input
                  type="text"
                  placeholder="Paste audio URL (only S3 supported)"
                  onPaste={handleUrlPaste}
                  className="url-input w-full px-4 py-2 border mb-2 mt-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300 "
                  disabled={dragging || recording}
                />
                <span className="text-white font-semibold text-lg ">
                  Paste a URL here to upload audio directly
                </span>
              </div>
            </div>
          </div>
        </div>

        {files.length > 0 && (
          <div className="pw-40 border-4 border-dashed border-gray-300 rounded-lg p-4 text-center transition duration-300 ease-in-out transform ">
            <div className="flex justify-between items-center text-lg mb-4">
              <div className="bg-blue-500 text-white px-3 py-1 rounded-md">
                Total Files: {files.length}
              </div>
              <button
                type="submit"
                className={classNames(
                  "upload-submit px-3 py-1 bg-blue-500 text-white rounded-md text-lg transition-all duration-300 transform hover:scale-105",
                  {
                    "opacity-50 cursor-not-allowed": uploading || dragging,
                  }
                )}
                onClick={handleSubmit}
                disabled={uploading || dragging}
              >
                {uploading ? (
                  <span className="flex items-center justify-center">
                    <FaSpinner className="animate-spin inline-block mr-2" />
                    Uploading...
                  </span>
                ) : (
                  <>
                    <FaPaperPlane className="inline-block mr-2" />
                    Upload
                  </>
                )}
              </button>
            </div>
            <ul className="list-disc list-inside max-h-14 overflow-y-auto p-0 m-0">
              {files.map((file, index) => (
                <li key={index} className="py-0 flex items-center">
                  <FaFileAudio className="text-gray-500 mr-2" />
                  {editingFileName === file ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        value={newFileName}
                        onChange={handleFileNameChange}
                        className="w-40 px-2 py-1 border border-gray-300 rounded-md mr-2"
                      />
                      <button
                        onClick={() => handleFileNameSubmit(file)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingFileName(null)}
                        className="text-red-500 hover:text-red-700 ml-2"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center ">
                      <span>{file.name}</span>
                      <button
                        className="text-green-950 ml-2 hover:text-blue-700 "
                        onClick={() => handleEditFileName(file)}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="ml-2 text-red-500 hover:text-red-700"
                        onClick={() => removeFile(file)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {showSuccess && (
        <div className="flex justify-center items-center mt-4">
          <div className="flex items-center bg-green-500 text-white text-lg font-bold px-4 py-2 rounded-lg animate-bounce">
            <FaCheckCircle className="mr-2" /> Successfully Uploaded!
          </div>
        </div>
      )}

      {showFailure && (
        <div className="flex justify-center items-center mt-4">
          <div className="flex items-center bg-red-500 text-white text-lg font-bold px-4 py-2 rounded-lg animate-bounce">
            <FaTimesCircle className="mr-2" /> Upload Failed!
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadAudio;
