import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player";
import { getTranscriptionByFileName } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";

const AudioCard = ({
  audio_file_name,
  audio_file_uri,
  timestamp,
  currentAudioRef,
  onDelete,
  onViewTranscription,
}) => {
  const audioPlayerRef = useRef(null);
  const navigate = useNavigate();

  const dateObj = new Date(timestamp);

  const formattedTime = dateObj.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const formattedDate = dateObj.toLocaleDateString();

  const handlePlay = () => {
    if (
      currentAudioRef.current &&
      currentAudioRef.current !== audioPlayerRef.current.audio.current
    ) {
      currentAudioRef.current.pause();
    }
    currentAudioRef.current = audioPlayerRef.current.audio.current;
  };

  const handleDelete = () => {
    onDelete(audio_file_name);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = audio_file_uri;
    link.download = audio_file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleViewTranscription = async () => {
    try {
      if (!audio_file_name) {
        console.error("Audio file name is undefined");
        return;
      }

      const transcription = await getTranscriptionByFileName(
        localStorage.getItem("token"),
        audio_file_name
      );
      navigate(`/transcription/${audio_file_name}`, {
        state: { transcription },
      });
    } catch (error) {
      console.error("Error fetching transcription:", error);
    }
  };

  return (
    <div className="audio-card p-4 border rounded shadow-lg  from-blue-100 to-indigo-200">
      <div className="audio-waveform mb-4">
        <AudioPlayer
          ref={audioPlayerRef}
          src={audio_file_uri}
          onPlay={handlePlay}
          showJumpControls={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          layout="horizontal-reverse"
        />
      </div>
      <div className="audio-details">
        <p>
          <strong>ID:</strong> {audio_file_name}
        </p>
        <p>
          <strong>Time:</strong> {formattedTime}
        </p>
        <p>
          <strong>Date:</strong> {formattedDate}
        </p>
        <div className="flex justify-between mt-4">
          <button
            className="btn-delete text-white px-3 py-2 rounded flex items-center space-x-2 transform transition-transform hover:scale-105 bg-red-500"
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
            <span>Delete</span>
          </button>
          <button
            className="btn-download text-white px-3 py-2 rounded flex items-center space-x-2 transform transition-transform hover:scale-105 bg-green-500"
            onClick={handleDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
            <span>Download</span>
          </button>
          <button
            className="btn-view text-white px-3 py-2 rounded flex items-center space-x-2 transform transition-transform hover:scale-105 bg-blue-500"
            onClick={handleViewTranscription}
          >
            <FontAwesomeIcon icon={faEye} />
            <span>View Transcription</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioCard;
