import React from "react";
import { FaUpload, FaDownload, FaTools, FaFileSignature } from "react-icons/fa";

const HowToTranscribe = () => {
  return (
    <section className="how-to-transcribe">
      <h2>
        How to Transcribe <span className="highlight">Audio Files</span>
      </h2>
      <div className="steps">
        <div className="step">
          <div className="step-icons">
            <FaUpload className="step-icon" />
          </div>
          <h1>Upload your audio file</h1>
          <p>
            Upload the file you want to transcribe, in any language. Select from
            over 100+ languages. Select Speaker Identification to label your
            speakers once the transcription has been completed.
          </p>
        </div>
        <div className="step">
          <div className="step-icons">
            <FaDownload className="step-icon" />
          </div>
          <h1>Download your transcript</h1>
          <p>
            Once your transcription has been uploaded, you can download it in
            various formats and preview each format's appearance. Should you
            choose speaker identification, these options will be accessible as
            well.
          </p>
        </div>
        <div className="step">
          <div className="step-icons">
            <FaTools className="step-icon" />
          </div>
          <h1>Manage your transcriptions</h1>
          <p>
            Your transcriptions are securely stored with us, ensuring
            confidentiality. On your dashboard, you can view, re-download in
            different formats, or delete your transcriptions, allowing easy
            management.
          </p>
        </div>
        <div className="step">
          <div className="step-icons">
            <FaFileSignature className="step-icon" />
          </div>
          <h1>Generate Insights</h1>
          <p>
            Generate summaries, sentiment analysis, named entity recognition
            (NER), and action points from your transcriptions to extract
            valuable information.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowToTranscribe;
