import React, { useEffect, useState, useRef } from "react";
import { getAllTranscriptions, deleteTranscription } from "../api";
import AudioCard from "./AudioCard";
import Pagination from "./Pagination";
import UploadAudio from "./UploadAudio";
import { FiSearch, FiChevronDown } from "react-icons/fi";

const MainContent = ({ token }) => {
  const [audioFiles, setAudioFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("time"); // Default sorting by time
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const filesPerPage = 6;
  const currentAudioRef = useRef(null);

  // Function to fetch transcriptions
  const fetchTranscriptions = async () => {
    try {
      const response = await getAllTranscriptions(token);
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        if (response.ok) {
          console.log(data); // Log the data to verify structure
          setAudioFiles(data);
        } else {
          console.error("Error fetching transcriptions:", data);
          setAudioFiles([]);
        }
      } else {
        console.error("Error: Expected JSON response but got", contentType);
        setAudioFiles([]);
      }
    } catch (error) {
      console.error("Error fetching transcriptions:", error);
      setAudioFiles([]);
    }
  };

  useEffect(() => {
    fetchTranscriptions();

    // Set up interval to fetch transcriptions every 30 seconds
    const intervalId = setInterval(() => {
      fetchTranscriptions();
    }, 30000); // 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [token]);

  const handleDelete = async (fileName) => {
    try {
      await deleteTranscription(token, fileName);
      setAudioFiles(
        audioFiles.filter((file) => file.audio_file_name !== fileName)
      );
    } catch (error) {
      console.error("Error deleting transcription:", error);
    }
  };

  // Handle case where audioFiles is not yet populated
  if (!Array.isArray(audioFiles)) {
    return <div>Loading...</div>;
  }

  // Sorting logic based on sortBy state
  let sortedFiles = [...audioFiles];
  switch (sortBy) {
    case "date":
      sortedFiles.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      break;
    case "time":
      sortedFiles.sort((a, b) => {
        // Assuming 'timestamp' is in ISO 8601 format or another sortable format
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
      break;
    case "alphabetical":
      sortedFiles.sort((a, b) =>
        a.audio_file_name.localeCompare(b.audio_file_name)
      );
      break;
    default:
      break;
  }

  // Filter files by searchTerm (assuming 'audio_file_name' exists)
  const filteredFiles = sortedFiles.filter((file) =>
    file.audio_file_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = filteredFiles.slice(indexOfFirstFile, indexOfLastFile);
  const totalPages = Math.ceil(filteredFiles.length / filesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="p-6 flex-1">
      <UploadAudio token={token} />
      <div className="flex justify-between mb-8 items-center mt-8">
        <div className="text-2xl font-bold underline-offset-4 text-green-950 ml-8 ">
          All Your Work History
        </div>
        <div className="flex items-center ml-auto">
          <div className="flex items-center relative mr-4">
            <label className="mr-2 text-lg font-semibold">Search:</label>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
              placeholder="Enter the file name"
              aria-label="Search for audio files"
            />
            <div className="absolute right-2 mr-2 text-gray-500">
              <FiSearch />
            </div>
          </div>
          <label className="mr-2 text-lg font-semibold">Sort by:</label>
          <div className="relative">
            <select
              value={sortBy}
              onChange={handleSortChange}
              className="p-2 border rounded-lg w-36 shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
            >
              <option value="date">Date</option>
              <option value="time">Time</option>
              <option value="alphabetical">Alphabetical</option>
            </select>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {currentFiles.map((file, index) => (
          <AudioCard
            key={file.audio_file_name} // Use audio_file_name as key
            {...file}
            currentAudioRef={currentAudioRef}
            onDelete={() => handleDelete(file.audio_file_name)}
          />
        ))}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default MainContent;
