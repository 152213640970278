import axios from "axios";

const API_URL = "https://axuat.atomstate.com/api";
export const login = async (credentials) => {
  const response = await fetch(`${API_URL}/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(credentials),
  });
  return response.json();
};

export const register = async ({ username, email, password }) => {
  try {
    const response = await axios.post(`${API_URL}/register/`, {
      username,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Registration error:", error.response?.data || error.message);
    throw error;
  }
};

export const uploadAudio = async (token, files) => {
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));

  const response = await fetch(`${API_URL}/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  return response.json();
};

export const getAllTranscriptions = async (token) => {
  const response = await fetch(`${API_URL}/transcriptions`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getTranscriptionByFileName = async (token, fileName) => {
  const response = await fetch(`${API_URL}/transcriptions/${fileName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export const generateSummary = async (token, fileName) => {
  const response = await fetch(`${API_URL}/summary/${fileName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data.summary; // Return only the summary string
};

export const analyzeSentiment = async (token, fileName) => {
  const response = await fetch(`${API_URL}/sentiment/${fileName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export const extractNamedEntities = async (token, fileName) => {
  const response = await fetch(`${API_URL}/ner/${fileName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export const extractActionPoints = async (token, fileName) => {
  const response = await fetch(`${API_URL}/actionpoints/${fileName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export const deleteTranscription = async (token, fileName) => {
  const response = await fetch(`${API_URL}/transcriptions/${fileName}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export const createCase = async (token, caseData) => {
  const response = await fetch(`${API_URL}/plugin/sfdc/create_case`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(caseData),
  });
  return response.json();
};

export const translateText = async (text, targetLanguage) => {
  try {
    const response = await axios.post(`${API_URL}/translate/`, {
      text,
      target_language: targetLanguage,
    });
    return response.data;
  } catch (error) {
    console.error("Error translating text:", error);
    throw error;
  }
};

export const getAllCases = async () => {
  try {
    const response = await axios.get(`${API_URL}/sfdc/get_all_cases`);
    return response.data;
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw error;
  }
};
