// src/components/Login.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../api";

const Login = ({ setToken, openRegisterModal }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const tokenData = await login({ username, password });
      setToken(tokenData.access_token); // Set token in state
      localStorage.setItem("token", tokenData.access_token); // Store the token in localStorage
      navigate("/home"); // Redirect to the home page upon successful login
    } catch (err) {
      setError("Invalid username or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="max-w-md w-full mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-semibold text-green-950 mb-6">
            Welcome back! Log in to continue.
          </h2>
        </div>
        <div className="bg-white p-8 rounded shadow-md">
          <form onSubmit={handleSubmit}>
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-300 rounded">
                {error}
              </div>
            )}
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <input
                id="username"
                type="text"
                className="mt-1 p-3 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                className="mt-1 p-3 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-950 hover:bg-green-900 text-white py-3 px-6 rounded transition duration-300"
              disabled={loading}
            >
              {loading ? "Please wait..." : "Login"}
            </button>
          </form>
        </div>
        <div className="mt-4 text-center">
          <span className="text-gray-400 mx-2">Don't have an account?</span>
          <button
            type="button" // Change to button type
            className="text-sm text-gray-400 hover:text-green-950"
            onClick={(e) => {
              e.preventDefault();
              openRegisterModal(); // Call the function to open the register modal
            }}
          >
            <p className="text-blue-500 hover:text-blue-600 text-sm">
              Back to Register
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
