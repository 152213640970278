import React, { useState, useRef } from "react";
import styled from "styled-components";
import {
  FaPlay,
  FaPause,
  FaDownload,
  FaForward,
  FaBackward,
} from "react-icons/fa";
import { MdSpeed } from "react-icons/md";

const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(1); // Volume state
  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value / 100) * duration;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "audio.wav";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePlaybackRateChange = (e) => {
    const newRate = parseFloat(e.target.value);
    audioRef.current.playbackRate = newRate;
    setPlaybackRate(newRate);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleForward = () => {
    audioRef.current.currentTime += 10;
  };

  const handleBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  return (
    <PlayerContainer>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <Controls>
        <IconButton onClick={handleBackward}>
          <FaBackward />
        </IconButton>
        <PlayPauseButton onClick={togglePlayPause} $isPlaying={isPlaying}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </PlayPauseButton>
        <IconButton onClick={handleForward}>
          <FaForward />
        </IconButton>
        <Progress>
          <ProgressBar
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100}
            onChange={handleSeek}
          />
        </Progress>
        <TimeDisplay>
          <Time>{formatTime(currentTime)}</Time>
          <Separator>/</Separator>
          <Time>{formatTime(duration)}</Time>
        </TimeDisplay>
        <SpeedControl>
          <SpeedLabel>
            <MdSpeed />
          </SpeedLabel>
          <SpeedSelect value={playbackRate} onChange={handlePlaybackRateChange}>
            <option value="0.5">0.5x</option>
            <option value="1">1x</option>
            <option value="1.5">1.5x</option>
            <option value="2">2x</option>
          </SpeedSelect>
        </SpeedControl>
        {/* <VolumeControl>
          <VolumeSlider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
          <VolumeIcon>
            {volume > 0.5 ? "🔊" : volume > 0 ? "🔉" : "🔇"}
          </VolumeIcon>
        </VolumeControl> */}
        <IconButton onClick={handleDownload}>
          <FaDownload />
        </IconButton>
      </Controls>
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 20px;
  width: fit-content;
  margin: 20px auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Increased gap for better spacing */
`;

const PlayPauseButton = styled.button`
  background: ${(props) => (props.$isPlaying ? "#e64a19" : "#0a6858")};
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  font-size: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => (props.$isPlaying ? "#0ea68c" : "#0ea68c")};
  }

  &:focus {
    outline: none;
  }
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 22px; /* Slightly larger icons */
  color: #0a6858;
  transition: color 0.3s ease;

  &:hover {
    color: #0ea68c;
  }

  &:focus {
    outline: none;
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px; /* Slightly larger font size */
  color: #333;
`;

const Time = styled.div`
  margin: 0 4px;
`;

const Separator = styled.div`
  margin: 0 4px;
`;

const Progress = styled.div`
  width: 120px; /* Wider progress bar */
  margin-top: -8px;
`;

const ProgressBar = styled.input`
  width: 100%;
  cursor: pointer;
  appearance: none;
  background: #0a6858;
  height: 6px; /* Slightly thicker progress bar */
  border-radius: 3px;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: #0a6858;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: ##0ea68c;
    }
  }
`;

const SpeedControl = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f1f1f1;
  padding: 5px;
  border-radius: 5px;
`;

const SpeedLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px; /* Larger speed icon */
  color: #0a6858;
`;

const SpeedSelect = styled.select`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px; /* Slightly larger padding */
  font-size: 14px;
  cursor: pointer;
  outline: none;
  background: #fff;
  color: #333;

  &:focus {
    border-color: #0a6858;
  }
`;

const VolumeControl = styled.div`
  display: flex;
  align-items: center;
  gap: 4px; /* Smaller gap */
`;

const VolumeIcon = styled.span`
  font-size: 16px; /* Smaller icon */
  color: #0a6858;
`;

const VolumeSlider = styled.input`
  width: 80px; /* More compact slider */
  cursor: pointer;
  appearance: none;
  background: #0a6858;
  height: 4px; /* Thinner slider */
  border-radius: 2px;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    background: #0a6858;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: #0ea68c;
    }
  }
`;

export default AudioPlayer;
