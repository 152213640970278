import React from "react";
import "./index.css";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

function ContactUs() {
  return (
    <div>
      <Header />
      <div className="contact-us">
        <div className="contact-us-form-map">
          <div className="contact-us-form">
            <div className="contact-us-header">
              <h1>Contact Us</h1>
              <p>
                We would love to hear from you! Please fill out the form below
                and we will get in touch with you shortly.
              </p>
            </div>
            <form>
              <div className="form-group">
                <label htmlFor="name">
                  <FontAwesomeIcon icon={faUser} /> Name:
                </label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <FontAwesomeIcon icon={faEnvelope} /> Email:
                </label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  <FontAwesomeIcon icon={faCommentDots} /> Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <button type="submit" className="sub-button">
                  Submit
                </button>
              </div>
            </form>
          </div>
          {/* <div className="contact-us-map">
            <div className="map-overlay"></div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.2934067102643!2d144.9631579513123!3d-37.81520617965226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218ce6e0!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sus!4v1631433485560!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Location Map"
            ></iframe>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
