import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faArrowUpRightFromSquare,
  faGlobe,
  faClock,
  faDollarSign,
  faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons";

const benefits = [
  {
    title: "Accuracy",
    description:
      "AI transcription tools rely on cutting-edge speech recognition algorithms and machine learning models, enabling them to achieve high levels of accuracy in transcribing audio recordings, even in challenging environments with background noise or multiple speakers",
    icon: faBullseye,
  },
  {
    title: "Scalability",
    description:
      "AI tools can handle transcription projects of any size, making them suitable for individuals, small businesses, and large enterprises alike, offering the flexibility to scale up or down as needed.",
    icon: faArrowUpRightFromSquare,
  },
  {
    title: "Multilingual Support",
    description:
      "Many AI transcription tools support a wide range of languages, enabling users to transcribe audio recordings in various languages, facilitating global collaboration and communication.",
    icon: faGlobe,
  },
  {
    title: "Efficiency",
    description:
      "Compared to manual transcription, AI tools can transcribe audio recordings at lightning-fast speeds, saving users countless hours of tedious work and enabling them to focus on more productive tasks.",
    icon: faClock,
  },
  {
    title: "Cost-effectiveness",
    description:
      "AI transcription services are often more cost-effective than hiring professional human transcriptionists, especially for large volumes of audio recordings or projects with tight deadlines.",
    icon: faDollarSign,
  },
  {
    title: "Accessibility",
    description:
      "Accurate transcripts can improve accessibility for individuals with hearing impairments or those who prefer to consume information in written form, promoting inclusivity and ensuring that valuable information is accessible to a wider audience.",
    icon: faUniversalAccess,
  },
];

const Benefits = () => {
  return (
    <section className="benefits">
      {benefits.map((benefit) => (
        <div className="benefit" key={benefit.title}>
          <div className="step-icons">
            <FontAwesomeIcon icon={benefit.icon} className="benefit-icon" />
          </div>
          <h3>{benefit.title}</h3>
          <p>{benefit.description}</p>
        </div>
      ))}
    </section>
  );
};

export default Benefits;
