import React from "react";
import ReactDOM from "react-dom";
import "./Modal.css"; // Ensure this file includes the styles for the modal
import { IoCloseCircleSharp } from "react-icons/io5";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="modal-close" onClick={onClose}>
            <IoCloseCircleSharp />
          </button>
          {children}
        </div>
      </div>
      <div className="backdrop"></div>
    </>,
    document.body
  );
};

export default Modal;
