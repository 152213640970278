import React from "react";
import headinImage from "../assets/heading.png";

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <h1>Audio Transcription.</h1>
        <h2>Powered by AI.</h2>
        <p>
          Transcribe audio & videos with 99% accuracy in 100+ languages and
          real-time change accuracy.
        </p>
        <button className="cta-button">Get Started Free</button>
        <div className="text-xs headcolor">
          Deign and developed by Suryakant Kumar [full stack developer
          -8051942924]
        </div>
      </div>
      <div className="header-image">
        <img src={headinImage} alt="heading" />
      </div>
    </header>
  );
};

export default Header;
