import React from "react";
import "./AboutUs.css"; // Import the updated CSS styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneAlt,
  faLanguage,
  faShieldAlt,
  faLightbulb,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import aboutImage from "../assets/robo.png";
import aboutVidio from "../assets/vedio.mp4";
import Footer from "./Footer";
import Header from "./Header";

const AboutUs = () => {
  return (
    <div>
      <Header />
      {/* About Us Section */}
      <section className="header">
        <div className="header-content-aboutus">
          <div className="p-8 rounded-lg">
            <h2 className="text-3xl font-bold mb-10">
              Welcome to <strong>AtomAX</strong>
            </h2>
            <p className="text-lg mb-4">
              <span>
                <FontAwesomeIcon
                  icon={faMicrophoneAlt}
                  className="text-blue-500 mr-2 mt-2"
                />
              </span>
              <span className="ml-2">
                <strong>AtomAX</strong> is an innovative conversational
                intelligence platform developed by Atomstate. Our advanced AI
                technology enhances live telephone conversations by providing
                real-time insights.
              </span>
            </p>
            <p className="text-lg mb-4">
              <FontAwesomeIcon
                icon={faLightbulb}
                className="text-yellow-500 mr-2 mt-2"
              />
              <span className="ml-2">
                We specialize in extracting critical data such as entities,
                sentiments, summaries, actionable items, and transcriptions.
              </span>
            </p>
            <p className="text-lg mb-4">
              <FontAwesomeIcon
                icon={faLanguage}
                className="text-green-500 mr-2 mt-2"
              />
              <span className="ml-2">
                Our platform offers seamless translation, ensuring smooth
                communication across various languages without the need for a
                common dominant language.
              </span>
            </p>
            <p className="text-lg mb-4">
              <FontAwesomeIcon
                icon={faShieldAlt}
                className="text-red-500 mr-2 mt-2"
              />
              <span className="ml-2">
                Headquartered at T Hub in Hyderabad, with branches in Delhi,
                Mumbai, and Bengaluru, AtomAX is expanding its global reach.
              </span>
            </p>
            <p className="text-lg mb-4">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-purple-500 mr-2 mt-2"
              />
              <span className="ml-2">
                We are excited to announce new entities in the USA, London, UK,
                and Perth soon, reflecting our commitment to delivering
                exceptional conversational intelligence solutions worldwide.
              </span>
            </p>
            <p className="text-lg mb-4">
              <FontAwesomeIcon
                icon={faMicrophoneAlt}
                className="text-blue-500 mr-2 mt-2"
              />
              <span className="ml-2">
                At AtomAX, we are dedicated to breaking down language barriers
                and fostering effective communication, no matter where you are
                or what language you speak.
              </span>
            </p>
            <p className="text-lg mb-4">
              <FontAwesomeIcon
                icon={faLightbulb}
                className="text-yellow-500 mr-2 mt-2"
              />
              <span className="ml-2">
                Experience the future of conversational intelligence with
                AtomAX—where every conversation is a gateway to understanding
                and collaboration.
              </span>
            </p>
          </div>
        </div>
        <div className="about-image">
          <img src={aboutImage} alt="About Us" style={{ width: "auto" }} />
        </div>
      </section>
      {/* Vision Section */}
      <section className="vision-section bg-gradient-to-r ">
        <div className="vision-section-content">
          <h2 className="text-3xl font-extrabold text-white mb-6">
            Our Vision
          </h2>

          <p className="text-lg text-white max-w-7xl flex mb-6">
            We envision a future where AI-powered transcription and language
            processing solutions become the cornerstone of global communication,
            breaking down language barriers and fostering seamless conversations
            across every industry and language. Our mission is to provide
            businesses and individuals with innovative tools that are accurate,
            secure, and easy to use, ensuring that language is never a barrier
            to understanding.
          </p>

          <p className="text-lg text-white max-w-7xl flex  mb-6">
            Imagine a world where you can understand and be understood, no
            matter what language you speak. Our cutting-edge AI technology
            translates any spoken language into any other language, offering
            instant insights like summaries, sentiment analysis, named entity
            recognition (NER), action points, and more. We are committed to
            pushing the boundaries of what's possible, making sure that
            communication is effortless and efficient.
          </p>
        </div>

        <ul className="values-list list-none space-y-4">
          <li className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faLightbulb}
              className="text-yellow-500 mr-3 text-2xl"
            />
            <span className="text-xl font-medium text-gray-800">
              Innovation:
            </span>
            <span className="text-lg text-gray-600 ml-2">
              We are constantly pushing the boundaries of what's possible.
            </span>
          </li>
          <li className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-red-500 mr-3 text-2xl"
            />
            <span className="text-xl font-medium text-gray-800">Security:</span>
            <span className="text-lg text-gray-600 ml-2">
              Your data's safety is our top priority.
            </span>
          </li>
          <li className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-green-500 mr-3 text-2xl"
            />
            <span className="text-xl font-medium text-gray-800">
              Reliability:
            </span>
            <span className="text-lg text-gray-600 ml-2">
              You can count on us for accurate and dependable solutions.
            </span>
          </li>
          <li className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faLanguage}
              className="text-blue-500 mr-3 text-2xl"
            />
            <span className="text-xl font-medium text-gray-800">
              Multilingual Support:
            </span>
            <span className="text-lg text-gray-600 ml-2">
              We support translation and transcription across all languages.
            </span>
          </li>
          <li className="flex items-center mb-4">
            <FontAwesomeIcon
              icon={faMicrophoneAlt}
              className="text-purple-500 mr-3 text-2xl"
            />
            <span className="text-xl font-medium text-gray-800">
              Real-Time Insights:
            </span>
            <span className="text-lg text-gray-600 ml-2">
              Get instant insights from your conversations.
            </span>
          </li>
        </ul>
      </section>

      {/* Video Section */}
      <div className="video-container">
        <video autoPlay loop muted>
          <source src={aboutVidio} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      </div>
      {/* Call to Action Section */}
      <section className="cta-section vision-section p-8 rounded-lg shadow-lg  text-center">
        <h2 className="text-2xl font-bold text-white mb-4">Join Us Today!</h2>
        <p className="text-lg text-white mb-4">
          Experience the future of conversational intelligence with AtomAX. Join
          us and be a part of the revolution in communication technology.
        </p>
        <button className="cta-button bg-yellow-500 font-bold py-2 px-4 rounded-full hover:bg-yellow-600 transition duration-300">
          Get Started
        </button>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
