// src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import History from "./components/History";
import Tickets from "./components/Tickets";
import TranscriptionDetails from "./components/TranscriptionDetails";
import RealTime from "./components/RealTime";
import HomePage from "./pages/Router";
import Contact from "./pages/ContactUs";
import FAQPage from "./pages/FAQPage";
import Price from "./pages/PricingPage";
import AboutUs from "./pages/AboutUs";
import { isValidToken } from "./utils/auth"; // Import the token validation function

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem("token"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const openRegisterModal = () => {
    setShowRegisterModal(true);
  };

  const PublicRoutes = () => (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/pricing" element={<Price />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  const ProtectedRoutes = () => {
    useEffect(() => {
      if (!isValidToken(token)) {
        handleLogout();
        window.location.href = "/"; // Redirect to homepage if token is invalid
      }
    }, [token]);

    // Render the protected components only if the token is valid
    if (!token || !isValidToken(token)) {
      return null; // Return nothing if token is invalid to avoid blank screen
    }

    return (
      <div className="flex">
        <Sidebar onLogout={handleLogout} />
        <div className="flex flex-col w-full TranscptionPgaes overflow-hidden">
          <Header />
          <Routes>
            <Route path="/home" element={<MainContent token={token} />} />
            <Route path="/audio-files" element={<History token={token} />} />
            <Route path="/tickets" element={<Tickets token={token} />} />
            <Route
              path="/transcription/:fileName"
              element={<TranscriptionDetails token={token} />}
            />
            <Route path="/realTime" element={<RealTime token={token} />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </div>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={token ? <ProtectedRoutes /> : <PublicRoutes />}
        />
      </Routes>
    </Router>
  );
}

export default App;
