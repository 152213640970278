import React from "react";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Header from "./Header";
import PriceImage from "../assets/pricing.png";
const Pricing = () => {
  return (
    <>
      <Header />
      <div className="pricing-container">
        <section className="header">
          <div className="header-content">
            <h1>Choose Your Plan</h1>
            <h2>Simple Pricing for Everyone</h2>
            <p>
              Select the plan that suits your needs. No hidden fees, no
              surprises.
            </p>
            <button className="cta-button">Get Started</button>
          </div>
          <div className="header-image pl-img">
            <img src={PriceImage} alt="Pricing Hero" />
          </div>
        </section>

        <section className="pricing-plans">
          <div className="pricing-table">
            <div className="pricing-plan">
              <div className="plan-header">
                <span>FREE</span>
                <p className="price">$0</p>
              </div>
              <ul className="features">
                <li>Nam sollicitudin dignissim</li>
                <li>Cras convallis lacus</li>
                <li>Quisque ut metus</li>
              </ul>
              <p className="plan-description">
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
              </p>
              <button className="choose-plan">CHOOSE PLAN</button>
            </div>

            <div className="pricing-plan best-value">
              <div className="plan-header">
                <span>BEST VALUE</span>
                <p className="price">$10</p>
              </div>
              <ul className="features">
                <li>Nam sollicitudin dignissim</li>
                <li>Cras convallis lacus</li>
                <li>Quisque ut metus</li>
                <li>Vivamus sit amet</li>
                <li>Cras convallis lacus orci</li>
              </ul>
              <p className="plan-description">
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
              </p>
              <button className="choose-plan">CHOOSE PLAN</button>
            </div>

            <div className="pricing-plan">
              <div className="plan-header">
                <span>PRO</span>
                <p className="price">$50</p>
              </div>
              <ul className="features">
                <li>Nam sollicitudin dignissim</li>
                <li>Cras convallis lacus</li>
                <li>Quisque ut metus</li>
                <li>Vivamus sit amet</li>
                <li>Cras convallis lacus orci</li>
              </ul>
              <p className="plan-description">
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
              </p>
              <button className="choose-plan">CHOOSE PLAN</button>
            </div>

            <div className="pricing-plan">
              <div className="plan-header">
                <span>VIP</span>
                <p className="price">$99</p>
              </div>
              <ul className="features">
                <li>Nam sollicitudin dignissim</li>
                <li>Cras convallis lacus</li>
                <li>Quisque ut metus</li>
                <li>Vivamus sit amet</li>
                <li>Cras convallis lacus orci</li>
              </ul>
              <p className="plan-description">
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
              </p>
              <button className="choose-plan">CHOOSE PLAN</button>
            </div>
          </div>
        </section>
      </div>
      <FAQ />
      <Footer />
    </>
  );
};

export default Pricing;
