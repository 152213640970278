import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation for active link detection
import logo from "../assets/atomax_small_1.png"; // Import the logo

const Footer = () => {
  const location = useLocation(); // Hook to get the current path

  return (
    <footer className="footer">
      <div className="footer-links">
        <div className="footer-top">
          <div className="footer-logo-content">
            <img src={logo} alt="Logo" className="footer-logo" /> {/* Logo */}
            <p>Your Best Choice for AI-Powered Solutions</p>
          </div>
        </div>
        <div>
          <h4>Main Links</h4>
          <ul>
            <li>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/AboutUs"
                className={location.pathname === "/AboutUs" ? "active" : ""}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/pricing"
                className={location.pathname === "/pricing" ? "active" : ""}
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Features</h4>
          <ul>
            <li>
              <Link
                to="/features/summaries"
                className={
                  location.pathname === "/features/summaries" ? "active" : ""
                }
              >
                Summaries
              </Link>
            </li>
            <li>
              <Link
                to="/features/speakers-text"
                className={
                  location.pathname === "/features/speakers-text"
                    ? "active"
                    : ""
                }
              >
                Speakers' Text
              </Link>
            </li>
            <li>
              <Link
                to="/features/recorded-errors"
                className={
                  location.pathname === "/features/recorded-errors"
                    ? "active"
                    : ""
                }
              >
                Recorded Errors
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Resources</h4>
          <ul>
            <li>
              <Link
                to="/docs"
                className={location.pathname === "/docs" ? "active" : ""}
              >
                Documentation
              </Link>
            </li>
            <li>
              <Link
                to="/api"
                className={location.pathname === "/api" ? "active" : ""}
              >
                API
              </Link>
            </li>
            <li>
              <Link
                to="/support"
                className={location.pathname === "/support" ? "active" : ""}
              >
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; 2024 <spam className="ax">.ax</spam> All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
